import { useNavigate } from '@remix-run/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { $path } from 'remix-routes';
import useSWR from 'swr';

import type {
  DtoAssignedCourse,
  DtoAssignedStack,
  DtoLearnerProfileResponse,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons/Arrows/Default';
import { CloseIcon } from '../../icons/CloseIcon';
import { MenuIcon } from '../../icons/MenuIcon';
import { StackedSquaresIcon } from '../../icons/StackedSquaresIcon';
import { getRandomAnimatedAvatar } from '../../Participant/avatars';

export function OverworldDrawerManager(props: {
  initialProfile: DtoLearnerProfileResponse;
  currentCourseId?: Nullable<string>;
}) {
  const { initialProfile, currentCourseId } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const { data: coursesData, isLoading: isCoursesLoading } = useSWR(
    '/my/learning-courses',
    async () => {
      const response = await apiService.learning.getUserCourses();
      return response.data;
    },
    {
      shouldRetryOnError: false,
    }
  );

  const { data: profile } = useSWR(
    '/my/learning-profile',
    async () => {
      const response = await apiService.learning.getMyLearnerProfile({
        enrollmentsSummary: true,
        membershipsSummary: false,
      });
      return response.data;
    },
    {
      shouldRetryOnError: false,
      fallbackData: initialProfile,
    }
  );

  const processedCourses = useMemo(() => {
    if (!coursesData?.courseData) return [];

    const courses: CourseListItem[] = [];

    if (coursesData.courses) {
      coursesData.courses.forEach((assignment: DtoAssignedCourse) => {
        const course = coursesData.courseData[assignment.id];
        if (course) {
          const { gamePack, progression } = course;
          const isCompleted = Boolean(progression?.completedAt);
          const progress = isCompleted
            ? 100
            : Math.floor((progression?.blockProgressPct ?? 0) * 100);

          courses.push({
            id: gamePack.id,
            name: gamePack.name,
            progress,
            isCompleted,
            active: gamePack.id === currentCourseId,
            type: 'course',
            assignedAt: assignment.assignedAt,
          });
        }
      });
    }

    if (coursesData.stacks) {
      coursesData.stacks.forEach((assignment: DtoAssignedStack) => {
        // Calculate average progress for all courses in the stack
        const courseProgresses = assignment.courseIds.map((courseId) => {
          const course = coursesData.courseData[courseId];
          if (!course) return 0;

          const { progression } = course;
          return progression?.completedAt
            ? 100
            : Math.floor((progression?.blockProgressPct ?? 0) * 100);
        });

        const averageProgress = courseProgresses.length
          ? Math.round(
              courseProgresses.reduce((acc, curr) => acc + curr, 0) /
                courseProgresses.length
            )
          : 0;

        const isCompleted = Boolean(assignment.completedAt);

        courses.push({
          id: assignment.id,
          name: assignment.name,
          progress: averageProgress,
          isCompleted,
          active: assignment.id === currentCourseId,
          type: 'stack',
          courseIds: assignment.courseIds,
          assignedAt: assignment.assignedAt,
        });
      });
    }

    return courses.sort((a, b) => b.assignedAt.localeCompare(a.assignedAt));
  }, [coursesData, currentCourseId]);

  const completedCount = profile.enrollmentsSummary?.completedCount ?? 0;
  const totalCount = profile.enrollmentsSummary?.totalCount ?? 0;

  const handleToggleDrawer = useLiveCallback(() => {
    setIsDrawerOpen(true);
  });

  const handleCloseDrawer = useLiveCallback(() => {
    setIsDrawerOpen(false);
  });

  const handleCourseClick = useLiveCallback(
    (courseType: 'stack' | 'course', courseId: string) => {
      handleCloseDrawer();
      if (courseType === 'stack') {
        navigate($path('/stacks/:id/overworld', { id: courseId }));
      } else {
        navigate($path('/game-packs/:id/overworld', { id: courseId }));
      }
    }
  );

  const hasCoursesAssigned =
    profile?.enrollmentsSummary && profile.enrollmentsSummary.totalCount > 0;

  if (!hasCoursesAssigned) return null;

  return (
    <>
      <div
        className='flex items-center gap-2 cursor-pointer'
        onClick={handleToggleDrawer}
      >
        <MenuIcon className='w-5 h-5' />
        <div className='flex items-center'>
          <span className='text-icon-gray text-xs'>My Courses</span>{' '}
          <span className='text-white text-xs ml-1 font-semibold'>
            {completedCount}/{totalCount}
          </span>
        </div>
      </div>

      <OverworldDrawer
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        profile={profile}
        courses={processedCourses}
        isLoading={isCoursesLoading}
        onCourseClick={handleCourseClick}
      />
    </>
  );
}

export function OverworldDrawer(props: {
  isOpen: boolean;
  onClose: () => void;
  profile: DtoLearnerProfileResponse;
  courses: CourseListItem[];
  isLoading: boolean;
  onCourseClick?: (courseType: 'stack' | 'course', courseId: string) => void;
}) {
  const { isOpen, onClose, profile, courses, isLoading, onCourseClick } = props;
  const drawerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const handleOpen = useLiveCallback(async () => {
    if (!drawerRef.current || !overlayRef.current) return;

    const drawerAnimation = drawerRef.current.animate(
      [{ transform: 'translateX(-100%)' }, { transform: 'translateX(0)' }],
      {
        duration: 175,
        fill: 'forwards',
        easing: 'ease-in-out',
      }
    );

    const overlayAnimation = overlayRef.current.animate(
      [{ opacity: 0 }, { opacity: 1 }],
      {
        duration: 175,
        fill: 'forwards',
        easing: 'ease-in-out',
      }
    );

    await Promise.all([drawerAnimation.finished, overlayAnimation.finished]);
  });

  const handleClose = useLiveCallback(async () => {
    if (!drawerRef.current || !overlayRef.current) return;

    const drawerAnimation = drawerRef.current.animate(
      [{ transform: 'translateX(0)' }, { transform: 'translateX(-100%)' }],
      {
        duration: 175,
        fill: 'forwards',
        easing: 'ease-in-out',
      }
    );

    const overlayAnimation = overlayRef.current.animate(
      [{ opacity: 1 }, { opacity: 0 }],
      {
        duration: 175,
        fill: 'forwards',
        easing: 'ease-in-out',
      }
    );

    await Promise.all([drawerAnimation.finished, overlayAnimation.finished]);
    onClose();
  });

  useEffect(() => {
    if (isOpen) {
      handleOpen();
    }
  }, [isOpen, handleOpen]);

  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 z-50'>
      <div
        ref={overlayRef}
        className='absolute inset-0 bg-black/50'
        onClick={handleClose}
      />

      <div
        ref={drawerRef}
        className='absolute top-0 left-0 bottom-0 w-75 bg-main-layer transform -translate-x-full'
      >
        <div className='w-full h-full flex flex-col gap-4 p-4 relative'>
          <button
            type='button'
            onClick={handleClose}
            className='absolute top-3 right-3 text-white hover:scale-110 transition-transform z-10'
          >
            <CloseIcon className='w-3 h-3 fill-current' />
          </button>

          <div className='flex-none mt-4'>
            <LearnerProfileCard profile={profile} />
          </div>

          <div className='flex-1 min-h-0 flex flex-col gap-4'>
            <h3 className='flex-none text-lg font-bold text-white'>
              My Courses
            </h3>
            <div className='flex-1 min-h-0 overflow-auto scrollbar'>
              {isLoading ? (
                <div className='text-icon-gray text-sm'>Loading courses...</div>
              ) : (
                <CoursesList courses={courses} onCourseClick={onCourseClick} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type CourseListItem = {
  id: string;
  name: string;
  progress: number;
  isCompleted: boolean;
  active?: boolean;
  type: 'course' | 'stack';
  courseIds?: string[];
  assignedAt: string;
};

function CoursesList({
  courses,
  onCourseClick,
}: {
  courses: CourseListItem[];
  onCourseClick?: (courseType: 'stack' | 'course', courseId: string) => void;
}) {
  const [completedSectionOpen, toggleCompletedSection] = useToggle(false);

  const activeCourses = courses.filter((course) => !course.isCompleted);
  const completedCourses = courses.filter((course) => course.isCompleted);

  return (
    <div className='space-y-4'>
      <div className='space-y-2'>
        {activeCourses.length === 0 && (
          <p className='text-icon-gray text-sm text-center'>
            Your enrolled courses
            <br />
            will appear here.
          </p>
        )}
        {activeCourses.map((course) => (
          <div
            key={course.id}
            className='relative cursor-pointer hover:bg-lp-black-003 transition-colors p-2'
            onClick={() =>
              !course.active && onCourseClick?.(course.type, course.id)
            }
          >
            {course.active && (
              <div className='absolute left-0 top-0 h-full w-1 bg-red-500 rounded-r' />
            )}

            <div
              className={`pl-3 ${
                course.active ? 'text-white font-semibold' : 'text-icon-gray'
              }`}
            >
              <div className='flex flex-col items-start justify-between'>
                <span className='break-words text-white'>
                  {course.name}
                  {course.type === 'stack' && (
                    <StackedSquaresIcon className='inline-block w-4 h-4 ml-2 fill-current' />
                  )}
                </span>
                <span className='text-xs text-gray-400'>
                  {course.progress}% complete
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {completedCourses.length > 0 && (
        <div className='pt-2'>
          <div
            className='flex items-center cursor-pointer mb-2 text-icon-gray'
            onClick={() => toggleCompletedSection()}
          >
            <span className='text-sm font-semibold mr-2'>Completed</span>
            {completedSectionOpen ? (
              <ArrowUpIcon className='w-3 h-3 fill-current' />
            ) : (
              <ArrowDownIcon className='w-3 h-3 fill-current' />
            )}
          </div>

          {completedSectionOpen && (
            <div className='space-y-2 mt-2'>
              {completedCourses.map((course) => (
                <div
                  key={course.id}
                  className='relative cursor-pointer hover:bg-lp-black-003 transition-colors p-2'
                  onClick={() =>
                    !course.active && onCourseClick?.(course.type, course.id)
                  }
                >
                  {course.active && (
                    <div className='absolute left-0 top-0 h-full w-1 bg-red-500 rounded-r' />
                  )}

                  <div
                    className={`pl-3 ${
                      course.active
                        ? 'text-white font-semibold'
                        : 'text-icon-gray'
                    }`}
                  >
                    <div className='flex flex-col items-start justify-between'>
                      <span className='break-words text-white'>
                        {course.name}
                        {course.type === 'stack' && (
                          <StackedSquaresIcon className='inline-block w-4 h-4 ml-2 fill-current' />
                        )}
                      </span>
                      <span className='text-xs text-green-001'>
                        100% complete
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function LearnerProfileCard(props: { profile: DtoLearnerProfileResponse }) {
  const { profile } = props;
  const activeCount = profile.enrollmentsSummary?.activeCount ?? 0;
  const completedCount = profile.enrollmentsSummary?.completedCount ?? 0;
  const totalCount = profile.enrollmentsSummary?.totalCount ?? 0;

  return (
    <div className='w-full'>
      <div className='w-full flex items-center gap-3'>
        <div className='flex-none rounded-full w-10 h-10 bg-secondary overflow-hidden'>
          <video
            src={getRandomAnimatedAvatar(profile.uid)}
            className='w-full h-full object-cover'
            muted
            autoPlay
            loop
            controls={false}
            playsInline
          />
        </div>
        <div className='flex-1 min-w-0'>
          <div className='w-full font-bold text-white text-lg truncate'>
            {profile.name}
          </div>
          <div className='w-full text-icon-gray text-xs truncate'>
            {profile.email}
          </div>
        </div>
      </div>

      {profile.enrollmentsSummary && (
        <div className='w-full flex items-center mt-3 mb-4 text-xs gap-3'>
          <div className='flex gap-1'>
            <span className='text-icon-gray'>Active</span>
            <span className='text-white font-bold'>{activeCount}</span>
          </div>
          <div className='flex gap-1'>
            <span className='text-icon-gray'>Completed</span>
            <span className='text-white font-bold'>{completedCount}</span>
          </div>
          <div className='flex gap-1'>
            <span className='text-icon-gray'>All Courses</span>
            <span className='text-white font-bold'>{totalCount}</span>
          </div>
        </div>
      )}
    </div>
  );
}
