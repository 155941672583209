import { useNavigate } from '@remix-run/react';
import { $path } from 'remix-routes';
import useSWR from 'swr';

import type {
  DtoAssignedCourse,
  DtoAssignedStack,
  DtoProgression,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import { CommonButton } from '../design/Button';

export function OverworldContinueButton(props: {
  packId: Nullable<string>;
  progression: Nullable<DtoProgression>;
  stackId?: Nullable<string>;
}) {
  const { stackId, packId, progression } = props;
  const navigate = useNavigate();

  const { call: handlePlay } = useLiveAsyncCall(async () => {
    if (!packId) return;
    if (!progression) {
      await apiService.progression.createMyProgression(packId);
    }

    const params = new URLSearchParams();
    if (stackId) {
      params.set('stack-id', stackId);
    }
    navigate(
      $path(
        '/game-packs/:id/play/:gameId?',
        { id: packId },
        Object.fromEntries(params)
      )
    );
  });

  const { data, isLoading } = useSWR(
    '/my/learning-courses',
    async () => {
      const response = await apiService.learning.getUserCourses();
      return response.data;
    },
    {
      shouldRetryOnError: false,
    }
  );

  // if we don't have a progression, we can show this immediately.
  if (!props.progression || props.progression.blockProgressPct === 0) {
    return (
      <CommonButton variant='correct' onClick={handlePlay}>
        Start Course
      </CommonButton>
    );
  }

  // if we aren't complete, we can show this immediately.
  if (!props.progression.completedAt) {
    return (
      <CommonButton variant='correct' onClick={handlePlay}>
        Continue
      </CommonButton>
    );
  }

  // so we're complete, but we don't know if we should show "next course" until
  // the data is loading...
  if (isLoading) {
    return (
      <CommonButton variant='gray' disabled>
        Loading...
      </CommonButton>
    );
  }

  if (data) {
    // we have some data, so let's look for an incomplete course
    const combined: (DtoAssignedCourse | DtoAssignedStack)[] = [
      ...(data.courses ?? []),
      ...(data.stacks ?? []),
    ];
    const maybeCourse = combined.filter((v) => !v.completedAt).at(0);
    if (maybeCourse) {
      return (
        <CommonButton
          variant='correct'
          onClick={() => {
            if ('courseIds' in maybeCourse) {
              navigate($path('/stacks/:id/overworld', { id: maybeCourse.id }));
            } else {
              navigate(
                $path('/game-packs/:id/overworld', { id: maybeCourse.id })
              );
            }
          }}
        >
          Next Course →
        </CommonButton>
      );
    }
  }

  // none of that worked out, maybe they finished it already...
  if (props.progression.updatedAt === props.progression.completedAt) {
    return (
      <CommonButton variant='gray' onClick={handlePlay}>
        Play Again?
      </CommonButton>
    );
  }

  // they are just replaying...let them continue...
  return (
    <CommonButton variant='correct' onClick={handlePlay}>
      Continue
    </CommonButton>
  );
}
